var mobile_nav = false;
var post =true;
$(document).ready(function() { 

    $('#form').off('submit').on('submit',function(event) { 
        event.preventDefault();
        if(post == false) {} else { 
            var data = $('#form').serialize();
            post = false;
            $.post('tg.php',data,function(data) {
                post = true;
                if(data == 'success') { 
                    $('#form').remove();
                    $('#form_success').fadeIn(0);
                } else { 
                    alert(data);
                }
            });
        
        }
    });


    $('a[data-scroll]').off('click').on('click',function() { 
        var x = $(this).attr('data-scroll');
        var top = $('.section[data-sroll="'+x+'"]').offset().top;
        $('html,body').animate({scrollTop: top},300);
        if(x == 'index') { $('#focus').focus();}
    });

    $('.contacts-maps').off('click').on('click',function() { $(this).removeClass('disabled');});
    
    $('#nav-bars').off('click').on('click',function() { 
        var state = $(this).hasClass('active');
        if(state == false) { 
            $(this).addClass('active');
            $('.navigation-tabs').css({'display':'table','left':$(this).offset().left});
            setTimeout(function() { mobile_nav = true;},100);
        } else { 
            $(this).removeClass('active');
            $('.navigation-tabs').fadeOut(0);
            mobile_nav = false;
        }
    })

    $(document).off('click').on('click',function() {
        if(mobile_nav == true) { 
        $('#nav-bars').removeClass('active');
        $('.navigation-tabs').fadeOut(0);    
        mobile_nav = false;
        }
    });

    $('#js-carousel-x1,#js-carousel-x2,#js-carousel-x3').owlCarousel({
        items:1,
        loop: true
    });

    $('#js-carousel-1').owlCarousel({
        nav: false,
        loop: true,
        margin: 120,
        responsive: {
            200: { 
                margin: 0,
                items: 1
            },
            600: { 
                margin: 10,
                items: 2
            },
            1200: { 
                margin: 60,
                items: 3
            }

        }
    });

    $('#js-carousel-2').owlCarousel({
        items: 2, 
        nav: true,
        loop: true,
        dotsEach: 1,
        margin: 40,
        responsive: {
            200: { 
                margin: 0,
                items: 1
            },
            1200: { 
                margin: 40,
                items: 2
            }

        }
    });


    $('.dash-tabs__link').off('click').on('click',function() { 
        if($(this).hasClass('active')) {} else { 
            $('.dash-tabs__link,.cnt').removeClass('active');
            $(this).addClass('active');
            $('.cnt[data-pid="'+$(this).attr('data-pid')+'"]').addClass('active');
        }
    });


});
